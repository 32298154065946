<template>
    <ol class="flex flex-wrap text-gray-800" data-cy="breadcrumbs">
        <li
            v-for="(breadcrumb, index) in breadcrumbs"
            :key="'breadcrumb-' + index"
            class="flex items-center"
        >
            <Icon
                v-if="index != 0"
                name="chevron-right"
                class="mx-2 text-[10px]"
            />
            <NuxtLink
                v-if="breadcrumb.path && breadcrumb.path !== ''"
                :to="breadcrumb.path"
                class="flex items-center font-bold text-grind no-underline"
            >
                {{ breadcrumb.label }}
            </NuxtLink>
            <span v-else class="text-grind">{{ breadcrumb.label }}</span>
        </li>
    </ol>
</template>

<script setup lang="ts">
import BreadcrumbType from '~/types/BreadcrumbType';

defineProps<{
    breadcrumbs: BreadcrumbType[];
}>();

const baseUrl = computed(() => {
    const { baseUrl } = useRuntimeConfig().public;
    return baseUrl;
});
</script>
