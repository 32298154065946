<template>
    <div class="relative flex flex-col">
        <div v-if="breadcrumbs.length" class="container relative z-50">
            <Breadcrumbs
                :breadcrumbs="breadcrumbs"
                class="absolute left-4 top-4"
            />
        </div>
        <slot />
        <StoryblokBlock
            v-for="(blok, index) in story.content.blocks"
            :key="blok._uid"
            :blok="blok"
            :next-block="story.content.blocks[index + 1]"
        />
    </div>
</template>

<script setup lang="ts">
import Breadcrumbs from '~/components/Breadcrumbs.vue';
import BreadcrumbType from '~/types/BreadcrumbType';
import { stripTrailingSlash } from '~/utils/helpers';

const props = defineProps<{
    story: any;
}>();

const breadcrumbs = computed<BreadcrumbType[]>(() => {
    if (!props.story.content.breadcrumbs) {
        return [];
    }
    return props.story.content.breadcrumbs.map((breadcrumb: any) => {
        let link;
        if (breadcrumb.link.linktype === 'story') {
            link = breadcrumb.link.story
                ? '/' + breadcrumb.link.story.full_slug
                : '';
        } else {
            link = '/' + breadcrumb.url;
        }
        return {
            label: breadcrumb.label,
            path: stripTrailingSlash(link),
        } as BreadcrumbType;
    });
});
</script>
