import { stripTrailingSlash } from '~/utils/helpers';

export default (story: any) => {
    const metaTags = story.content.SEOMetatags;

    if (metaTags) {
        useSeoMeta({
            title: metaTags.title,
            description: metaTags.description,
            ogTitle: metaTags.og_title,
            ogDescription: metaTags.og_description,
            ogImage: metaTags.og_image,
            twitterTitle: metaTags.twitter_title,
            twitterDescription: metaTags.twitter_description,
            twitterImage: metaTags.twitter_image,
            robots: {
                noindex: story.content.robots_noindex,
                nofollow: story.content.robots_noindex,
            },
        });
    }

    if (!metaTags || metaTags.title === '') {
        useHead({
            title: 'Roastar | ' + story.name,
        });
    }

    const canonical = story.content.canonical;
    const storySlug = story.full_slug !== 'home' ? '/' + story.full_slug : '';
    let canonicalUrl = `${useRuntimeConfig().public.baseUrl}${storySlug}`;
    if (canonical) {
        if (canonical.story) {
            const storyUrl =
                canonical.story.url !== '/' ? '/' + canonical.story.url : '';
            canonicalUrl = useRuntimeConfig().public.baseUrl + storyUrl;
        } else {
            if (canonical.url !== '') {
                canonicalUrl = canonical.url;
            }
        }
    }
    canonicalUrl = stripTrailingSlash(canonicalUrl);

    useServerHead({
        link: [
            {
                rel: 'canonical',
                href: canonicalUrl,
            },
        ],
    });
};
