import { trim } from 'lodash-es';

interface Options {
    contentType?: string;
    folder?: string;
}

export default async function (providedOptions: Options = {}) {
    const config = useRuntimeConfig();
    const route = useRoute();
    const storyblokApi = useStoryblokApi();

    const { contentType, folder } = {
        contentType: null,
        folder: null,
        ...providedOptions,
    };

    const release = Object.keys(route.query).includes('_storyblok_release')
        ? (route.query._storyblok_release as string)
        : null;

    const resolveRelations = toRaw(config.public.storyblok.resolveRelations);

    let contentTypesString =
        config.public.storyblok.queryContentTypes.join(',');
    if (contentType) {
        contentTypesString = Array.isArray(contentType)
            ? contentType.join(',')
            : contentType;
    }

    let urlEndpoint: string | null = folder;
    if (route.params.slug) {
        urlEndpoint = Array.isArray(route.params.slug)
            ? trim(route.params.slug.join('/'), '/')
            : route.params.slug;
        if (folder) {
            urlEndpoint = `${folder}/${urlEndpoint}`;
        }
    }

    if (!urlEndpoint) {
        urlEndpoint = 'home';
    }

    const story = ref<any>(null);

    const storiesResponse = await useAsyncData('vue', async () =>
        storyblokApi.get(`cdn/stories`, {
            filter_query: {
                component: { in: contentTypesString },
            },
            version: isLivePreview() ? 'draft' : 'published',
            from_release: release || '',
            resolve_relations: resolveRelations,
            resolve_links: 'url',
            by_slugs: urlEndpoint + ',' + urlEndpoint + '/',
        }),
    );
    const data = storiesResponse.data.value;

    story.value = data?.data.stories.length ? data.data.stories[0] : null;

    if (!story.value) {
        throw createError({
            statusCode: 404,
            statusMessage: 'Page Not Found',
            data: {
                fullPath: route.fullPath,
                params: route.params,
                path: route.path,
                meta: route.meta,
                name: route.name,
                hash: route.hash,
            },
        });
    }

    const initBridge = () => {
        useStoryblokBridge(
            story.value.id,
            (evStory) => {
                story.value = evStory;
            },
            { resolveRelations },
        );
    };

    return { story, initBridge };
}
